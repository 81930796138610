/*
* 업무구분: 이벤트 &gt; 한글자 운세
* 화면 명: MSPSA071M
* 화면 설명: 기본정보 입력
*/
<template>
  <ur-page-container title="기본정보입력" :show-title="true" type="subpage">
    <!-- 한글자운세_전체 S -->
    <ur-box-container direction="column" alignV="start" alignH="start" componentid="" class="nw-qw-wrap nw-unse-wrap">
      <!-- 한글자운세_기본정보입력 S -->
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column"  class="ns-add-area nw-unse-add-area pal0">
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="add-box nw-add-box">
          <span class="title">고객명</span>
          <mo-text-field v-model="lv_CustInfo.custNm" ref="custNm" @keyup.enter="fn_OpenMSPCM005P" :rules="validateRuleName" searchable placeholder="고객명" @click-icon="fn_OpenMSPCM005P" underline class="nw-input"/>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="add-box">
          <span class="title">성별</span>
          <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="sexCd">
              <mo-segment-button value="M">남자</mo-segment-button>
              <mo-segment-button value="F">여자</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>

        </ur-box-container>
        <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="add-box">
          <span class="title">생년월일</span>
          <ur-box-container alignV="start" componentid="ur_box_container_006" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="lv_Cldr">
              <mo-segment-button value="0">양력</mo-segment-button>
              <mo-segment-button value="1">음력(평달)</mo-segment-button>
              <mo-segment-button value="2">음력(윤달)</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="ur_box_container_007" direction="column" class="add-box">
          <div class="ns-date-picker">
            <mo-date-picker v-model="lv_BrthYmd" placeholder="선택일" class="ns-date-picker"/>
          </div>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="ur_box_container_008" direction="column" class="add-box pb160">
          <msp-bottom-select class="ns-dropdown-sheet" ref="bottomSelect" :items="filterSelectItemList" v-model="filterSelectValue" :itemValue="'key'" :itemText="'label'" underline bottom-title="태어난 시간을 선택해 주세요." closeBtn placeholder="태어난 시간을 선택해 주세요."/>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="ur_box_container_009" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ViewRslt">운세보기</mo-button>
          </div>
        </ur-box-container>
      </ur-box-container>
      <!-- 한글자운세_기본정보입력 E -->
    </ur-box-container>
    <!-- 한글자운세_전체 E-->
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import MSPCM005P from '@/ui/cm/MSPCM005P' // 고객선택
  import MSPSA072P from '@/ui/sa/MSPSA072P'
  import moment from 'moment'
  import Screen from '~systems/mixin/screen'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPSA071M",
    screenId: "MSPSA071M",
    mixins: [Screen],
    components: {
      'MSPCM005P': MSPCM005P,
      'MSPSA072P': MSPSA072P,
      MspBottomSelect
    },
    props: {
    },
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    /************************************************************************************************
     * 화면 전역 변수 선언 영역
     ************************************************************************************************/
    data() {
      return {
        lv_ThisYear: '2025', // 토정비결 결과 년도 이므로 1년에 한번 변경 필요
        validateRuleName: [
            (v) => !!v || '필수입력항목입니다.'
        ],
        lv_BrthYmd: '1970-01-01',
        lv_AlertPop: {},
        pCustInfo: {},
        pLuckData: {},
        pThisYear: '2025',
        lv_CustInfo: {
          custNm: '',
          chnlCustId: '',
          sxdsCdNm: '',
          ralDob: '',
          emailAddr: '',
          knb: ''
        },
        lv_LuckData: {
          HanJa: '',
          HanCons: '',
          HanImg: '',
          GoodMonth: '',
          BadMonth: '',
          Summary: '',
          MoneyNum: '',
          BizNum: '',
          HealthNum: '',
          LoveNum: '',
          Mon: []
        },
        sexCd: 'M',
        filterSelectItemList: [
          { key: '0', label: '00:00~01:30 조자시' },
          { key: '2', label: '01:31~03:30 축시' },
          { key: '4', label: '03:31~05:30 인시' },
          { key: '6', label: '05:31~07:30 묘시' },
          { key: '8', label: '07:31~09:30 진시' },
          { key: '10', label: '09:31~11:30 사시' },
          { key: '12', label: '11:31~13:30 오시' },
          { key: '14', label: '13:31~15:30 미시' },
          { key: '16', label: '15:31~17:30 신시' },
          { key: '18', label: '17:31~19:30 유시' },
          { key: '20', label: '19:31~21:30 술시' },
          { key: '22', label: '21:31~23:30 해시' },
          { key: '24', label: '23:31~24:00 야자시' }
        ],
        filterSelectValue: '0',
        lv_Cldr: '0',
        popUp005: {}
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    watch: {
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /*********************************************************
       * Function명: fn_OpenMSPCM005P
       * 설명: 고객등록 / 선택 팝업 Open/Close
       * Return: N/A
       *********************************************************/
      fn_OpenMSPCM005P() {
        console.log('ㅁㅁㅁㅁㅁMSPCM005P 호출')
        if (this.lv_CustInfo.custNm.trim() === '') {
          this.fn_BottomAlert('고객명을 입력하세요.')
          return
        } else {
          let lv_Vm = this
          lv_Vm.popUp005 = lv_Vm.$bottomModal.open(MSPCM005P, {
            properties: {
              pCustNm: lv_Vm.lv_CustInfo.custNm,
              useMktCheck: true,
              isUnseCust: true
            },
            listeners: {
              confirmPopup: (pData) => {
                console.log('fn_OpenMSPCM005P', pData)
                lv_Vm.lv_CustInfo.custNm = pData.custNm
                lv_Vm.lv_BrthYmd = moment(lv_Vm.$bizUtil.addBirthFrontYear(pData.rrnFrnoNo)).format('YYYY-MM-DD')
                lv_Vm.sexCd = ['1', '3', '5', '7', '9'].indexOf(pData.knb.substring(6, 7)) > -1 ? 'M' : 'F'
                // custInfo setting
                lv_Vm.lv_CustInfo.custNm = pData.custNm
                lv_Vm.lv_CustInfo.chnlCustId = pData.chnlCustId
                lv_Vm.lv_CustInfo.sxdsCdNm = lv_Vm.sexCd === 'M' ? '남성' : '여성'
                lv_Vm.lv_CustInfo.ralDob = lv_Vm.$bizUtil.addBirthFrontYear(pData.rrnFrnoNo)
                lv_Vm.fn_selCustDetailInfo()
                lv_Vm.$bottomModal.close(lv_Vm.popUp005)
              }
            }
          })
        }
      },
      /******************************************************************************
       * Function명 : fn_selCustDetailInfo
       * 설명       : 고객 상세 인적 사항 조회
       ******************************************************************************/
      fn_selCustDetailInfo () {
        let lv_Vm = this

        let pParams = {
          'cnsltNo': this.getStore('userInfo').getters.getUserInfo.userEno, // 컨설턴트사번
          'chnlCustId': lv_Vm.lv_CustInfo.chnlCustId
        }
        let trnstId = 'txTSSCM50S1' // 고객상세인적사항 조회 [IF-CM-0015: F1MDI0270]

        this.post(lv_Vm, pParams, trnstId, 'S')
          .then(function (response) {
            if (response.body !== null && response.body !== '') {
              let t_CustInfo = {}
              t_CustInfo = response.body
              lv_Vm.lv_CustInfo.emailAddr = t_CustInfo.emailAddr
              lv_Vm.lv_CustInfo.knb = t_CustInfo.knb
            } else {
              // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },
      /******************************************************************************
       * Function명 : fn_ViewRslt
       * 설명       : 운세 보기
       ******************************************************************************/
      fn_ViewRslt () {
        let lv_Vm = this
        if (lv_Vm.lv_CustInfo.custNm.trim() === '') {
          // lv_Vm.fn_BottomAlert('고객명을 입력하세요.')
          lv_Vm.$refs.custNm.validate()
          return
        } else {
          // // ******* 로컬 OR 개발계 테스트용 *******
          if (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development') {

            lv_Vm.lv_CustInfo.sxdsCdNm = lv_Vm.sexCd === 'M' ? '남성' : '여성'
            lv_Vm.lv_CustInfo.ralDob = moment(lv_Vm.lv_BrthYmd).format('YYYYMMDD')
            lv_Vm.lv_CustInfo.emailAddr = 'kyukyoung.jung@stage.samsung.com'

            lv_Vm.lv_LuckData.HanJa = '별 성'
            lv_Vm.lv_LuckData.HanCons = '나를 바라보는 사람이 많아지는 해입니다. 성과도 많지만 해야 할 일도 많아지네요. 보는 눈이 많으니 매사가 신중하긴 하지만 즐거움은 끝이없습니다.'
            lv_Vm.lv_LuckData.HanImg = '032'
            lv_Vm.lv_LuckData.GoodMonth = '12'
            lv_Vm.lv_LuckData.BadMonth = '4'
            lv_Vm.lv_LuckData.Summary = '말을 타고 장안(長安)을 달리니 봄바람에 뜻을 얻게 되는 형국입니다. 봄바람이 화창하니 복사꽃과 오얏꽃이 가득 피어나고 그 꽃이 향기를 전하니 나의 이름이 사방에 알려지게 됩니다. 맑은 물이 가득하니 산 그림자가 강에 잠기고 밝은 달빛을 받으며 높은 곳에 오르니 일신이 저절로 편안해지니 운수가 대길할 기운을 품게 되는군요. 집안에 경사가 있지만 식구끼리 한번은 다투게 되므로 화목을 위해서는 손실을 한번은 감수해야 합니다. 나를 도와주는 귀인이 들어오니 반가이 맞이하시기 바랍니다. 단비와 기름진 이슬이 초목을 윤택하게 할 것이며 지성을 다하면 하늘을 감동시켜 길운을 더욱 크게 할 수 있습니다. 대길운에 소원성취이니 시간을 아끼시기 바랍니다.'
            lv_Vm.lv_LuckData.MoneyNum = '88'
            lv_Vm.lv_LuckData.BizNum = '85'
            lv_Vm.lv_LuckData.HealthNum = '82'
            lv_Vm.lv_LuckData.LoveNum = '86'
            lv_Vm.lv_LuckData.Mon = [
              {
                data: '매사에 주의하고 경거망동 하지 말아야 합니다. 아직 때가 이르지 않았음에 먼저 나서면 잃을 것이 많으니 주의하고 조신하면 잃지 않고 기다릴 수 있을 것입니다.'
              },
              {
                data: '매사에 기쁨이 넘치고 수익도 많은 달입니다. 그러나 나를 시기하는 자가 있군요. 얻은 것을 자랑하면 도둑을 불러 들일 것이니 겸손한 마음을 유지해야 합니다.'
              },
              {
                data: '나를 돕는 기운이 충만하니 노력을 한다면 큰 성과를 낼 수 있습니다. 좋은 기운이 가족으로 부터 나오게 시간을 할애하여 친목을 도모할수 있는 시간을 만드세요.'
              },
              {
                data: '다른 사람의 말을 듣고 진행한 일이 있다면 처음부터 다시 계획을 잡아 진행하는것이 좋습니다. 약속이나 계획에 차질이 오지만 본인은 이를 감당하기가 힘듭니다.'
              },
              {
                data: '재물이 들어와도 나갈 곳이 있으니 기쁨도 반입니다. 아깝고 안타까운 마음이 많이 드는 시기이니 이번 달은 무엇보다도 마음의 여유를 많이 가지셔야 합니다.'
              },
              {
                data: '가뭄에 단비가 내리는 형국입니다. 비로소 막혔던 일이 풀리게 되니 그 시원함은 말로 표현할 방법이 없을 것이며 그간의 심적 고통이 한순간 해결되어지게 됩니다.'
              },
              {
                data: '준비가 덜 된 상황에서 일을 벌이게 됩니다. 처음은 어렵게 진행될 것입니다. 결과는 좋을 것이니 처음의 어려움을 두려워 해선 안되는데 쉽지는 않군요.'
              },
              {
                data: '천금을 만지는 듯한 행운을 얻을수 있습니다. 백가지 일마다 순조롭게 잘풀리게 되니 재물이 따라오는것은 당연한 이치입니다. 무엇을 하던 좋은 결과일 것입니다.'
              },
              {
                data: '시간을 들이면 모두 재물이 되고 노력하여 얻지 못하는 것이 없습니다. 재운이 크고 능력이 돋보이는 때입니다. 다만 좋지 않은 인연으로 망신을 당할 운도 있습니다.'
              },
              {
                data: '다툼이 많은 달이며 본인이나 가까운 사람이 병원에 입원을 하게 되는 시기입니다. 마음이 아프고 외로움이 많이 찾아 드는 시기인데 절대로 지치셔서는 안 됩니다'
              },
              {
                data: '외부의 사람들과 함께 일을 도모하면 안됩니다. 자신의 기운은 좋으나 들어오는 기운은 좋지 못합니다. 어려움이 있어도 가급적 스스로 처리해야 합니다.'
              },
              {
                data: '진행은 원활하지만 인연이 떠나는 달이니 편치 못합니다. 성과는 좋지만 과정이 깔끔하지 않지요. 마음을 두지 못하니 결과는 얻겠으나 심신이 괴로운 달입니다.'
              }
            ]
            // test
            lv_Vm.pCustInfo = lv_Vm.lv_CustInfo,
            lv_Vm.pLuckData = lv_Vm.lv_LuckData,
            lv_Vm.pThisYear = lv_Vm.lv_ThisYear
            this.fn_OpenMSPSA072P()
            // ***************************************
          } else {
            // 신비운에 보낼 paramter 세팅
            let t_Year = this.lv_BrthYmd.substring(0, 4)
            let t_Month = this.lv_BrthYmd.substring(5, 7)
            let t_Day = this.lv_BrthYmd.substring(8, 10)
            let t_Url = 'http://samsung.sinbiun.co.kr/comm/evtHanJa/GetHanJaData.asp?byear=' + t_Year + '&bMonth=' + t_Month + '&bDay=' + t_Day +
                  '&bHour=' + this.filterSelectValue + '&bLunar=' + this.lv_Cldr + '&bSex=' + this.sexCd + '&unseYear=' + this.lv_ThisYear

            let t_Json
            let t_JsonData

            lv_Vm.lv_CustInfo.sxdsCdNm = lv_Vm.sexCd === 'M' ? '남성' : '여성'
            lv_Vm.lv_CustInfo.ralDob = moment(lv_Vm.lv_BrthYmd).format('YYYYMMDD')

            // 신비운 데이터
            try {
              window.fdpbridge.exec('sinbiunPlugin', { path: t_Url }, function (result) {
                // 성공콜백
                // result.data 는 xml 이므로 json으로 변경하기
                const convert = require('xml-js')
                t_Json = convert.xml2json(result.data, {compact: true, spaces: 4})
                
                t_JsonData = JSON.parse(t_Json)

                lv_Vm.lv_LuckData.HanJa = t_JsonData.root.HanJa._text
                lv_Vm.lv_LuckData.HanCons = t_JsonData.root.HanCons._text
                lv_Vm.lv_LuckData.HanImg = t_JsonData.root.HanImg._text
                lv_Vm.lv_LuckData.GoodMonth = t_JsonData.root.GoodMonth._text
                lv_Vm.lv_LuckData.BadMonth = t_JsonData.root.BadMonth._text
                lv_Vm.lv_LuckData.Summary = t_JsonData.root.Summary._text
                lv_Vm.lv_LuckData.MoneyNum = t_JsonData.root.MoneyNum._text
                lv_Vm.lv_LuckData.BizNum = t_JsonData.root.BizNum._text
                lv_Vm.lv_LuckData.HealthNum = t_JsonData.root.HealthNum._text
                lv_Vm.lv_LuckData.LoveNum = t_JsonData.root.LoveNum._text
                lv_Vm.lv_LuckData.Mon = [
                  { data: t_JsonData.root.Mon.data[0]._text },
                  { data: t_JsonData.root.Mon.data[1]._text },
                  { data: t_JsonData.root.Mon.data[2]._text },
                  { data: t_JsonData.root.Mon.data[3]._text },
                  { data: t_JsonData.root.Mon.data[4]._text },
                  { data: t_JsonData.root.Mon.data[5]._text },
                  { data: t_JsonData.root.Mon.data[6]._text },
                  { data: t_JsonData.root.Mon.data[7]._text },
                  { data: t_JsonData.root.Mon.data[8]._text },
                  { data: t_JsonData.root.Mon.data[9]._text },
                  { data: t_JsonData.root.Mon.data[10]._text },
                  { data: t_JsonData.root.Mon.data[11]._text }
                ]
                // test
                lv_Vm.pCustInfo = lv_Vm.lv_CustInfo,
                lv_Vm.pLuckData = lv_Vm.lv_LuckData,
                lv_Vm.pThisYear = lv_Vm.lv_ThisYear
                lv_Vm.fn_OpenMSPSA072P()
              }, function (result) {
                // 실패콜백
                console.log('실패' + result.data)
              })
            } catch (error) {
              console.log('## 070 error: ', error)
              window.vue.error(error)
            }
          }
        }
      },
      /******************************************************************************
       * Function명 : fn_OpenMSPSA072P
       * 설명       : 한글자 운세 요약
       ******************************************************************************/
      fn_OpenMSPSA072P () {
        this.lv_Popup072 = this.$bottomModal.open(MSPSA072P, {
          properties: {
          pPage: 'MSPSA072P',
          pIsBackKeyListen: true,
          pCustInfo: this.lv_CustInfo,
          pLuckData: this.lv_LuckData,
          pThisYear: this.lv_ThisYear,
          pSrnId: 'MSPSA071M'
        },
        listeners: {
          closeDivision: () => {
            console.log('closeDivision MSPSA072P')
            this.$router.push( { name: 'MSPSA070M' } )
            this.$bottomModal.close(this.lv_Popup072)
          }
          }
        })
      },
      /******************************************************************************
       * Function명 : fn_BottomAlert
       * 설명       : 알림 팝업 호출
       ******************************************************************************/
      fn_BottomAlert(contents) {
        this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            single: true,
            title: '알림',
            content: contents
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(this.lv_AlertPop);
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_AlertPop);
            }
          }
        })
      },
    },
  };
</script>